import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  Autocomplete,
  Button,
  FileUpload,
  Loader,
  Modal,
  Notification,
  Textbox,
} from 'ui/components';
import {
  buscaNrDocumentoFatura,
  importaFaturaEnergia,
} from 'core/services/ENE/importaFaturaEnergia';
import { MaskTypes, ResponseStatus, Theme } from 'ui/Helpers/enums';
import { MimeTypes } from 'ui/Helpers/utils';
import { Message } from 'ui/Helpers/interfaces';
import { getPocTipoOrcamentoAutoComplete } from 'core/services/POC/pocTipoOrcamento';
import { PocTipoOrcamento } from 'core/models/POC';

interface IProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  afterImport: (() => void) | null;
  setCSDSelPageMessage: Dispatch<SetStateAction<Message>>;
}

export default function ModalImportaFaturaDeEnergia({
  isOpen,
  setIsOpen,
  afterImport = null,
  setCSDSelPageMessage,
}: IProps) {
  const [message, setMessage] = useState<Message | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [files, setFiles] = useState<File[]>([]);
  const [documento, setDocumento] = useState<string>('');
  const [documentoIncompleto, setDocumentoIncompleto] = useState<boolean>(
    false
  );
  const [nrDescontoOfertado, setNrDescontoOfertado] = useState<number>(0);
  const [tipoOrcamento, setTipoOrcamento] = useState<PocTipoOrcamento>();
  const [mask, setMask] = useState<number>(MaskTypes.CPF);

  useEffect(() => {
    setFiles([...files]);
  }, [isOpen]);

  const onSetMessage = (status: number, msg: string) => {
    if (msg) {
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    }
  };

  const getDocument = async (items: File[]) => {
    if (isOpen && items.length > 0) {
      setLoading(true);

      const { message: msg, status, value } = await buscaNrDocumentoFatura(
        items
      );

      if (msg) {
        setMessage({
          message: msg,
          theme:
            status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
        });

        setLoading(false);

        return;
      }

      if (value.length === 11) {
        setMask(MaskTypes.CPF);
      } else {
        setMask(MaskTypes.CNPJ);
      }

      if (value.includes('*')) {
        setDocumentoIncompleto(true);
      }

      setDocumento(value);

      setLoading(false);
    }
  };

  const handleImport = async (): Promise<void> => {
    setLoading(true);

    if (
      (documentoIncompleto &&
        documento.length !== 11 &&
        documento.length !== 14) ||
      documento.includes('*')
    ) {
      setMessage({
        message:
          'Como nao foi possível localizar o numero de documento na fatura, é necessário que ele seja informado.',
        theme: Theme.Warning,
      });

      setLoading(false);
      return;
    }

    if (
      !nrDescontoOfertado ||
      nrDescontoOfertado < 0 ||
      nrDescontoOfertado > 15
    ) {
      setMessage({
        message: 'Desconto ofetado deve ser entre 0 e 15%.',
        theme: Theme.Warning,
      });

      setLoading(false);
      return;
    }

    if (!tipoOrcamento?.nrSeqPocTipoOrcamento) {
      setMessage({
        message: 'Necessário selecionar o tipo de orçamento a ser gerado.',
        theme: Theme.Warning,
      });

      setLoading(false);
      return;
    }

    if (files.length > 0) {
      const { message: msg, status } = await importaFaturaEnergia(
        files,
        documento,
        nrDescontoOfertado,
        tipoOrcamento.nrSeqPocTipoOrcamento
      );

      if (msg && status === ResponseStatus.Success) {
        setCSDSelPageMessage({
          message: msg,
          theme: Theme.Success,
        });
        setIsOpen(false);
      } else if (msg && status === ResponseStatus.Error) {
        setMessage({
          message: msg,
          theme: Theme.Danger,
        });
      }
    } else {
      setMessage({
        message: 'Nenhum arquivo enviado para importação.',
        theme: Theme.Warning,
      });
    }

    if (afterImport != null) {
      afterImport();
    }

    setLoading(false);
  };

  const onSearchTipoOrcamento = async (
    e: string
  ): Promise<PocTipoOrcamento[]> => {
    const {
      status,
      message: msg,
      data: tipos,
    }: {
      status: number;
      message: string;
      data: PocTipoOrcamento[];
    } = await getPocTipoOrcamentoAutoComplete({
      noPocTipoOrcamento: e,
    });

    onSetMessage(status, msg);

    return tipos;
  };

  return (
    // @ts-expect-error
    <Modal
      title='Importar Fatura de Energia'
      onClose={() => setIsOpen(false)}
      show={isOpen}
    >
      <Loader loading={loading} />
      {/* @ts-expect-error */}
      <Modal.Body>
        {message && (
          <div className='mb-3'>
            <Notification
              floatable
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}

        <div className='mb-3'>
          <FileUpload
            files={files}
            onChange={async (items: File[]) => {
              setFiles(items);

              getDocument(items);

              if (items.length === 0) {
                setDocumentoIncompleto(false);
                setDocumento('');
              }
            }}
            allowedMimeTypes={[MimeTypes.Types.PDF]}
            dropzone
            maxFiles={1}
          />
        </div>

        <div className='d-flex justify-content-center'>
          <div className='d-flex flex-column justify-content-center  w-100'>
            <div className='mb-3'>
              <div className='alert alert-info fs-8' role='alert'>
                {documentoIncompleto && (
                  <>
                    <p>
                      Não foi possível localizar o numero do documento do
                      cliente <b>(CPF/CNPJ)</b> completo na fatura, é necessário
                      informar no campo abaixo, para que seja possível realizar
                      a importação com sucesso.
                    </p>

                    <div className='mb-3'>
                      {mask === MaskTypes.CPF ? (
                        // @ts-expect-error
                        <Textbox
                          label='CPF'
                          mask={documento.includes('*') ? null : MaskTypes.CPF}
                          required={documento.includes('*')}
                          text={documento}
                          onChangedValue={(text: string) => setDocumento(text)}
                        />
                      ) : (
                        // @ts-expect-error
                        <Textbox
                          label='CNPJ'
                          mask={documento.includes('*') ? null : MaskTypes.CNPJ}
                          required={documento.includes('*')}
                          text={documento}
                          onChangedValue={(text: string) => setDocumento(text)}
                        />
                      )}
                    </div>
                  </>
                )}

                <div className='mb-3'>
                  {/* @ts-expect-error */}
                  <Textbox
                    label='Desconto Ofertado (%)'
                    type='number'
                    text={nrDescontoOfertado}
                    required
                    onChangedValue={(value: number) =>
                      setNrDescontoOfertado(value)
                    }
                  />
                </div>

                <div className='mb-3'>
                  <Autocomplete
                    label='Tipo Orçamento'
                    searchDataSource={onSearchTipoOrcamento}
                    required
                    selectedItem={tipoOrcamento}
                    onSelectItem={(tipoOrc: PocTipoOrcamento) =>
                      setTipoOrcamento(tipoOrc)
                    }
                    dataSourceTextProperty='noPocTipoOrcamento'
                  />
                </div>
              </div>
            </div>

            <div className='align-self-center'>
              <Button
                text='Importar'
                onClick={handleImport}
                theme={Theme.Primary}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
